import './components/style.css'

import { DownOutlined, LinkOutlined, SearchOutlined } from '@ant-design/icons'
import { Col, Collapse, Input, Tabs } from 'antd'
import { IntlMessages, Title } from 'components'
import { onValue, ref } from 'firebase/database'
import { database } from 'firebaseConfig'
import React, { useCallback,useEffect, useState } from 'react'
import { event, pageview, timing } from 'react-ga'
import { injectIntl } from 'react-intl'
import { withRouter  } from 'react-router-dom'
import { COLORS } from 'Utils/constants'
import { setSuccessMessage } from 'Utils/OrderUtils'

const { TabPane } = Tabs

const { Panel } = Collapse

declare global {
  interface Window {
    chrome: any;  
    options: {
      scroll: any;
    }
  }
}
export interface IFaqProps {
  disabled?: boolean
  dataSource?: any
  label?: string
  tabPosition?: any
  items?: any
  intl: any
  match: any
  location: any
}
const FAQ: React.FC<IFaqProps> = (props: IFaqProps) => {
  const [activeCategory, setActiveCategory] = useState('0')
  const [FAQData, setFAQData] = useState(null)
  const [categories, setCategories] = useState([])
  const [searchVal, setSearchVal] = useState('')
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [expendableQuestionKey, setExpendableQuestionKey] = useState(null)
  const [expendableActiveFilteredQuestionKeys, setExpendableActiveFilteredQuestionKeys] = useState([])

  useEffect(() => {
    const userRef = ref(
      database,
      '1GmVuEHIaH1f9iwJ7_vYT9RMzeX5S5uBy4SfBW786uHY'
    )
  
    const param = new URLSearchParams(props.location.search)
    const questionId = param.get('id')
  
    // Expending the Question if QuestionId is present in route
    setExpendableQuestionKey(questionId)
  
    // Tracking number of users visiting page
    pageview(window.location.pathname + window.location.search)
  
    // Fetching Questions from DB
    onValue(userRef, snapshot => {
      if (snapshot.exists()) {
        const usersArray = Object.entries(snapshot.val().Sheet1).map(
          ([id, data]: any) => ({
            id,
            ...data
          })
        )
  
        categorizeFAQs(usersArray)
        setData(usersArray)
  
        // Scroll to the specific question if the questionId is present
        if (questionId) {
          setTimeout(() => {
            const element = document.getElementById(questionId)

            if (element) {
              element.scrollIntoView({ behavior: 'smooth' })
            }
          }, 100) // Delay to ensure the DOM is fully updated
        }
      } else {
        console.log('No data available!')
      }
    })
  
    // Tracking the time spent on the FAQ section
    const startTime = Date.now()
    const intervalId = setInterval(() => {
      const currentTime = Date.now()
      const elapsedTime = Math.round((currentTime - startTime) / 1000) // Convert to seconds
      
      timing({
        category: 'FAQ',
        variable: 'Time Spent',
        value: elapsedTime,
      })
    }, 1000) // Update every second
  
    return () => clearInterval(intervalId)
  }, [props.location.search])
  
  const handleCategoryButtonClick = (categoryName) => {
    // Tracking the number of clicks on the Categories
    event({
      category: 'Category',
      action: 'Button Click',
      label: categoryName,
    })
  }

  const categorizeFAQs = faqs => {
    const categories = {}

    faqs.forEach(faq => {
      const category = faq['FAQ Category ']

      if (!categories[category]) {
        categories[category] = []
      }
      categories[category].push({
        id: faq.id,
        addedOn: faq['FAQ Added on'],
        answer: faq['FAQ Answer'],
        question: faq['FAQ Question']
      })
    })
    setFAQData(categories)
    setCategories(Object.keys(categories))
  }

  useEffect(() => {
    if (!FAQData) return null
    // Filter the data based on the search term
    const filteredData = data?.filter(item =>
      item['FAQ Answer'].toLowerCase().includes(searchVal.toLowerCase())
    )

    // Tracking the search history and frequency of the word searched
    event({
      category: 'Question Search',
      label: searchVal,
      action: ''
    })
    
    const activeFilterIds = []

    filteredData?.forEach(elem => activeFilterIds.push(elem.id))
    
    searchVal.length && setExpendableActiveFilteredQuestionKeys(activeFilterIds)
    categorizeFAQs(filteredData || data)
    setFilteredData(filteredData)

  }, [searchVal])

  // Function to highlight text
  const highlightText = (text) => {
    const regex = new RegExp(searchVal, 'gi')

    return text.replace(regex, (match) => (
      `<span style="background-color: yellow">${match}</span>`
    ))
  }

  const handleItemClick = key => {
    const category = categories[key]
    const element = document.getElementById(category)

    setActiveCategory(key)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const copyPath = id => {
    const currentUrl = new URL(window.location.href)

    currentUrl.searchParams.set('id', id) // Set or update the 'id' parameter
  
    navigator.clipboard
      .writeText(currentUrl.toString())
      .then(() => {
        setSuccessMessage('Copied to clipboard', props.intl)
        setExpendableQuestionKey(id)
      })
      .catch(err => {
        console.error('Failed to copy: ', err)
      })
  }
  
  const genExtra = id => (
    <LinkOutlined
      style={{
        color: COLORS.ICON,
        fontSize: 15,
        marginRight: 30,
        cursor: 'pointer'
      }}
      onClick={() => copyPath(id)}
    />
  )

  return (
    <div className="main main-container">
      <div className="navbar-block">
        <Col span={22}>
          <Title level="h2">
            <IntlMessages id="faq.title" />
          </Title>
        </Col>
        ̦
      </div>
      <div className="search-input">
        <Input
          className='search-input'
          placeholder="Search in FAQ"
          onChange={e => setSearchVal(e.target.value)}
          prefix={
            <SearchOutlined
              style={{
                fontSize: 16,
                color: '#CCCCCC'
              }}
            />
          }
        />
        {searchVal?.length ? (
          <div
            style={{
              marginTop: 5
            }}>{`Search results for "${searchVal}" (${filteredData.length})`}</div>
        ) : null}
      </div>
      <div className='question-container'>
        <div className="content">
          {FAQData &&
            Object.keys(FAQData).map((section, index) => (
              <div key={index} id={section} className="category">
                <div className="section-title">{section}</div>
                {FAQData[section].map((item, itemIndex) => (
                  <Collapse
                    activeKey={expendableQuestionKey}
                    bordered={false}
                    key={item.id}
                    onChange={(keys) => {
                      event({
                        category: 'Dropdown',
                        label: `Question ID:${item.id} & Question: ${item.question}`,
                        action: 'Clicked',
                      })
                      if (keys.length > 0) {
                        setExpendableQuestionKey(item.id) // Expand the clicked question
                      } else {
                        setExpendableQuestionKey(null) // Collapse the clicked question
                      }
                    }}
                    expandIconPosition={'right'}
                    expandIcon={({ isActive }) => (
                      <DownOutlined rotate={isActive ? 180 : 0} />
                    )}>
                    <Panel
                      id={item.id}
                      extra={genExtra(item.id)}
                      header={`${itemIndex + 1}. ${item.question}`}
                      key={item.id}
                      className="answer">
                      <div dangerouslySetInnerHTML={{ __html: highlightText(item.answer) }} />
                    </Panel>
                  </Collapse>
                ))}
              </div>
            ))}
        </div>
        <div className="sidebar">
          <div className="sidebar-title">TABLE OF CONTENTS</div>
          <Tabs activeKey={activeCategory} tabPosition={'right'} onChange={key => handleItemClick(key)}>
            {categories?.map((category, index) => (
              <TabPane tab={<div onClick={() => handleCategoryButtonClick(category)}>{category}</div>} key={index} />
            ))}
          </Tabs>
          <div></div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(withRouter(FAQ))