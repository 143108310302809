import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'

const firebaseConfig = {
  apiKey: 'AIzaSyD98AwxvwEW0khBTsJ2NEpV7krCy0I_ovU',
  authDomain: 'poc-excelsheet.firebaseapp.com',
  databaseURL: 'https://poc-excelsheet-default-rtdb.firebaseio.com',
  projectId: 'poc-excelsheet',
  storageBucket: 'poc-excelsheet.appspot.com',
  messagingSenderId: '683092752021',
  appId: '1:683092752021:web:b37d48c2f684aed2d04d9c'
}

const app = initializeApp(firebaseConfig, 'other')
const database = getDatabase(app)

export { database }