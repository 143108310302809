// eslint-disable-next-line import/prefer-default-export
export const ROUTES = {
  // Auth ROUTES
  LOGIN_PAGE: '/signin',
  FORGOT_PASSWORD_PAGE: '/forgotpassword',
  RESET_PASSWORD_PAGE: '/reset', // Todo: change it later
  INITIAL_RESET_PASSWORD_PAGE: '/changePassword',

  // Onboarding ROUTES
  ONBOARDING_PROFILE: '/orderx/profile',
  ONBOARDING_PAGE: '/orderx/onboarding',
  ONBOARDING_MINI_URL: '/orderx/onboarding/create-mini-url',
  ONBOARDING_GPAY_REGISTRATION: '/orderx/onboarding/gpay-registration',
  ONBOARDING_GPAY_MERCHANT: '/orderx/onboarding/gpay-merchant',

  // Store ROUTES
  STORE_LIST: '/orderx/store-list',
  STORE_PAGE: '/orderx/store',
  STORE_EDIT_PAGE: '/orderx/store/:id/edit',

  // MENU ROUTES
  MENU_PAGE: '/orderx/menu-list/:id/detail',
  MENU_LIST: '/orderx/menu-list',
  MENU_EDIT: '/orderx/menu-list/:id/customize',

  // Order ROUTES
  ORDER_REPORT: '/orderx/reports',
  ORDER_PAGE: '/orderx/orders',
  ORDER_HISTORY: '/orderx/order-history',

  // Help Routes
  FAQ: '/orderx/faq'
}
