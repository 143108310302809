import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'
import { ConfigProvider } from 'antd'
import { Location } from 'history'
import AppLocale from 'i18n'
import jwt from 'jsonwebtoken'
import * as compose from 'lodash/flowRight'
import { FAQ,ForgotPassword, InitialResetPassword, Login, ResetPassword } from 'pages'
import * as React from 'react'
import { initialize } from 'react-ga'
import { IntlProvider } from 'react-intl'
import { Redirect, Route, Switch } from 'react-router-dom'
import {
  getOrgDetails,
  getUserDetails,
  isAdmin,
  isOnboaringRoute,
  registerServiceWorker
} from 'Utils'
import { ORG_STATUS } from 'Utils/constants'
import { ROUTES } from 'Utils/constants/routes'

import FbNotificationManager from '../../../firebaseInit'
import MainApp from './MainApp'
/*
 * Const ForgotPassword = lazy(() => import('../../routes/ForgotPassword'));
 * const ChangePassword = lazy(() =>
 *   import('../../routes/ForgotPassword/ChangePassword')
 * );
 * const ResetPassword = lazy(() =>
 *   import('../../routes/ForgotPassword/ResetPassword')
 * );
 * const SignIn = lazy(() => import('../../routes/SignIn'));
 * const MainApp = lazy(() => import('./MainApp'));
 */

const RestrictedRoute = ({
  setRedirectRoute,
  component: Component,
  ...rest
}: any) => (

  <Route
    {...rest}
    render={props => {
      const { location } = props
      const jwtData: any = localStorage.getItem('jwt')
        ? jwt.decode(localStorage.getItem('jwt'))
        : undefined

      const userDetails = getUserDetails()

      if (jwtData && jwtData.org_id && jwtData.id) {
        console.log('Path---->', location.pathname)
        if (isAdmin() && Boolean(userDetails) && userDetails.resetPassword) {
          return <Redirect to={{ pathname: '/changePassword' }} />
        }
        if (
          isAdmin() &&
          getOrgDetails().onboardingStatus === ORG_STATUS.INITIATED &&
          !isOnboaringRoute(location.pathname)
        ) {
          return <Redirect to={{ pathname: '/orderx/onboarding' }} />
        }
        if (
          (isAdmin() && Boolean(userDetails) && !userDetails.resetPassword) ||
          !isAdmin()
        ) {
          return <Component {...props} />
        }
      }

      localStorage.clear()
      setRedirectRoute({ variables: { route: location.pathname } })

      return (
        <Redirect
          to={{ pathname: ROUTES.LOGIN_PAGE, state: { from: location } }}
        />
      )
    }}
  />
)

interface IProps {
  location?: Location
  locale?: {
    locale: any
  }
  userId?: string
  match: any
  setRedirectRoute: any
  ReactGA: any
}

interface IState { }

class App extends React.Component<IProps, IState> {
  componentDidMount() {
    initialize(process.env.REACT_APP_GA_TRACKING_KEY)
    if (navigator.serviceWorker) {
      const NotificationManager = FbNotificationManager.getInstance()

      NotificationManager.initNotificationListener()
      registerServiceWorker()
    }
  }

  render() {
    const { location, match, setRedirectRoute } = this.props

    if (location.pathname === '/') {
      return <Redirect to={ROUTES.LOGIN_PAGE} />
    }

    // TODO: Handle location
    const currentAppLocale = AppLocale.en
    const jwtData: any = localStorage.getItem('jwt')
      ? jwt.decode(localStorage.getItem('jwt'))
      : undefined
    
    return (
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>
          <Switch>
            <Route
              exact
              path={ROUTES.LOGIN_PAGE}
              render={props => <Login {...props} />}
            />
            <Route
              exact
              path={ROUTES.INITIAL_RESET_PASSWORD_PAGE}
              render={props => < InitialResetPassword {...props} />}
            />
            <Route exact path={ROUTES.FORGOT_PASSWORD_PAGE} component={ForgotPassword} />
            <Route exact path={ROUTES.RESET_PASSWORD_PAGE} component={ResetPassword} />
            {!jwtData ? (<Route exact path={ROUTES.FAQ} component={FAQ} />):null}

            <RestrictedRoute
              path={`${match.url}`}
              // UserId={userId}
              component={MainApp}
              setRedirectRoute={setRedirectRoute}
            />
          </Switch>
        </IntlProvider>
      </ConfigProvider>
    )
  }
}

const GET_SETTINGS = gql`
  query localData {
    settings @client {
      locale {
        locale
        name
        languageId
        icon
      }
    }
    auth @client {
      userId
    }
  }
`
const mapStateToProps = ({ localData }: any) => {
  const { locale } = localData.settings
  const { userId } = localData.auth

  return { locale, userId }
}
const SET_REDIRECT_ROUTE = gql`
  mutation setRedirectRoute($route: String) {
    setRedirectRoute(route: $route) @client
  }
`

export default compose(
  graphql(SET_REDIRECT_ROUTE, { name: 'setRedirectRoute' })
  /*
   * Graphql(GET_SETTINGS, {
   *   props: mapStateToProps,
   *   name: 'localData'
   * })
   */
)(App)
