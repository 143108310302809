/* eslint-disable quotes */
export const PROFILE = {
  CHANGE_DETAIL_TEXT: 'To change any of the above details write to us: ',
  HELP_EMAIL: 'help@peppo.co.in'
}

/* Below errors are not shown to user*/
export const ERROR_EXCEPTIONS = [
  'Internal server error',
  'Action not allowed to perform',
  'Product discount value not found'
]

export const API_ERROR = {
  UNF: 'User not found, please type your username again',
  // UNTH: 'Password doesn’t match. Please type again',
  UPDNM: 'Password doesn’t match. Please type again',
  tnv: 'Link invalid. Request a new link to reset your password'
}

export const DEALS_TABS_HELPER_TEXT = {
  transactionHistory:
    'Click here to view information regarding all the redemptions that have taken place',
  settlement:
    'Click here to view information regarding all the redemptions that have been settled by Peppo',
  invoices:
    'Click here to view the monthly commission deduction invoice of deals.',
  brandDetailsReport:
    'Click here to view the cinema venue wise performance of the brand',
  brandOutletRedemptions:
    'Click here to view the outlet wise performance of the brand'
}

export const PROMOTIONS_DOWNLOADS_FILE = 'TransactionHistory.csv'
export const CONSOLE_VERSION = 'V.32.2023.01.12.001'
export const RADIUS = 'RADIUS'
export const MERCHANT_DISCOUNT = 'MERCHANT_DISCOUNT'
export const DELIVERY_PARTNER = 'DELIVERY_PARTNER'
export const SETTLEMENT_REPORT = 'Settlement Report'
export const PEPPO_GSTIN = '29AALCP2385H1ZY'
export const CANCEL_REASONS = [
  'Rider not available',
  "Too many order! The store can't accept any new now"
]
export const SELF_PiCKUP_CANCEL_REASONS = [
  "Too many orders! The store can't accept the order now.",
  'Customer denied pickup',
  'Other'
]

export const REJECT_REASONS = [
  'Rider not available',
  'Items are out of stock',
  "Too many orders! The store is busy/can't accept any now"
]
export const SELF_PiCKUP_REJECT_REASONS = [
  'Some of the items are out of stock',
  "Too many orders! The store can't accept the order now.",
  'Self Pickup not supported',
  'Other'
]
