/* eslint-disable multiline-comment-style */
import { QuestionCircleOutlined } from '@ant-design/icons'
import { DatePicker, Row, Table, Tabs as AntDTabs, Tooltip } from 'antd'
import { ICONS } from 'assets'
import {
  Button,
  Dropdown,
  IntlMessages,
  Modal,
  Search,
  Tabs,
  Text,
  TrimmedText
} from 'components'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import TimeAgo from 'react-timeago'
import { getMetabaseUrl } from 'request'
import { getExcelRedemptions, getRedemptions } from 'services/promotionService'
import { isAdmin, setErrMsg } from 'Utils'
import {
  COLORS,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DEALS_TABS_HELPER_TEXT,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_START_DATE,
  END_TIME,
  HOUR_TO_MINUTE_TIME_FORMAT,
  MAX_ITEMS_PER_PAGE,
  REDEMPTION_STATUS,
  RUPEE,
  START_TIME,
  YEAR_TO_DATE_FORMAT
} from 'Utils/constants'
import { getWcoreOrganizationId } from 'Utils/localStorageHandlers/getter'
import { getUserData } from 'Utils/onboardingUtils'

import MetabaseReports from './MetabaseReports'

const { TabPane } = AntDTabs
const { RangePicker } = DatePicker

const RenderPriceValue = ({ value }) => <div>{`${RUPEE} ${value}`}</div>

const transactionHistoryTableColumns = [
  {
    title: 'Time',
    key: 'transactionTime',
    dataIndex: 'transactionTime',
    width: '11%',
    render: dateTime => (
      <div>
        {dateTime ? (
          <>
            <div>{dateTime?.time}</div>
            <div>{dateTime?.date}</div>
          </>
        ) : (
          <div> - </div>
        )}
      </div>
    )
  },
  {
    title: 'Transaction ID',
    key: 'transactionId',
    dataIndex: 'transactionId',
    width: '25%'
  },
  {
    title: 'Store Details',
    key: 'storeDetails',
    dataIndex: 'storeDetails',
    render: storeDetails => (
      <div>
        <TrimmedText limitText={1} text={storeDetails?.storeName || ''} />
        <TrimmedText limitText={1} text={storeDetails?.venueName || ''} />
      </div>
    )
  },
  {
    title: 'Bill Amount',
    key: 'billAmount',
    dataIndex: 'billAmount',
    width: '8%',
    render: billAmount => <RenderPriceValue value={billAmount} />
  },
  {
    title: 'Merchant Discount',
    key: 'merchantDiscount',
    dataIndex: 'merchantDiscount',
    width: '8%',
    render: merchantDiscount => <RenderPriceValue value={merchantDiscount} />
  },
  {
    title: 'Other Offers',
    key: 'otherOffers',
    dataIndex: 'otherOffers',
    width: '8%',
    render: otherOffers => <RenderPriceValue value={otherOffers} />
  },
  {
    title: 'Amount Paid By Customer',
    key: 'amountPaidByCustomer',
    dataIndex: 'amountPaidByCustomer',
    width: '10%',
    render: amountPaidByCustomer => (
      <RenderPriceValue value={amountPaidByCustomer} />
    )
  },
  {
    title: 'Transaction Status',
    key: 'transactionStatus',
    dataIndex: 'transactionStatus',
    width: '12%'
  },
  {
    title: 'Coupon Code',
    key: 'couponCode',
    dataIndex: 'couponCode',
    width: '10%'
  }
]

function formatTransactionHistoryTableData(responseData) {
  return responseData.map(redemption => {
    const {
      coupon,
      redemption_id,
      redemption_status,
      redemption_date,
      claim: { store_promotion, customer },
      payment: { payment_last_modified_time },
      redemption_details: { bill_amount, discount, payable_bill_amount }
    } = redemption

    let transactionTime = null
    const transactionDateTime = redemption_date || payment_last_modified_time

    if (transactionDateTime) {
      transactionTime = {
        date: moment(transactionDateTime).format(DATE_FORMAT),
        time: moment(transactionDateTime).format(HOUR_TO_MINUTE_TIME_FORMAT)
      }
    }

    const {
      direct_discount_percentage: discountPercentage
    } = store_promotion.store_promotion_details

    return {
      key: redemption_id,
      transactionTime,
      transactionId: redemption_id,
      storeDetails: {
        storeName: store_promotion.store.store_name,
        venueName: store_promotion.store.venue.name
      },
      billAmount: bill_amount,
      merchantDiscount: discount,
      otherOffers: 0,
      amountPaidByCustomer: payable_bill_amount,
      transactionStatus: redemption_status,
      couponCode: coupon?.coupon_code || '-'
    }
  })
}

// Below constants are specific to this component hence kept here
let data: object[] = []
const endDateTime = new Date()
const startDateTime = new Date(DEFAULT_START_DATE)
const DEFAULT_START_DATE_TIME = moment(startDateTime)
  .set(START_TIME)
  .format(DATE_TIME_FORMAT)
const DEFAULT_END_DATE_TIME = moment(endDateTime)
  .set(END_TIME)
  .format(DATE_TIME_FORMAT)
const DEFAULT_ACTIVE_KEY = '1'

function PromotionsHistory() {
  const { stores } = getUserData()
  const [selectedStore, setSelectedStore] = useState<any>({})
  const [storeStartDate, setStoreStartDate] = useState<any>(DEFAULT_START_DATE)
  const [storeEndDate, setStoreEndDate] = useState<any>(DEFAULT_END_DATE_TIME)
  const [transactionData, setTransactionData] = useState<object[]>([])
  const [IsLoading, setIsLoading] = useState<boolean>(false)
  const [dates, setDates] = useState<any>([])
  const [searchString, setSearchString] = useState<string>('')
  const [activeTabIndex, setActiveTabIndex] = useState<string>(
    DEFAULT_ACTIVE_KEY
  )
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(
    DEFAULT_PAGE_NUMBER
  )
  const [totalCount, setTotalCount] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(MAX_ITEMS_PER_PAGE)
  const [brandReport, setBrandReport] = useState(null)
  const [settlementReport, setSettlementReport] = useState(null)
  const [brandOutletRedemptionUrl, setBrandOutletRedemptionUrl] = useState(null)
  const [
    merchantSettlementMemoReport,
    setMerchantSettlementMemoReport
  ] = useState(null)
  const [showPopup, setShowPopup] = useState(false)
  const [isDownloadDataLoading, setIsDownloadDataLoading] = useState(false)

  const lastUpdatedTime = useRef<any>(endDateTime)
  const isAdminUser = isAdmin()
  const intl = useIntl()

  async function getTransactions(customPageNumber = null) {
    const [startDate, endDate] = dates
    const shiftedStartDateTime =
      startDate && startDate.set(START_TIME).format(DATE_TIME_FORMAT)
    const shiftedEndDateTime =
      endDate && endDate.set(END_TIME).format(DATE_TIME_FORMAT)
    const dateRange = {
      startDateTime: shiftedStartDateTime || DEFAULT_START_DATE_TIME,
      endDateTime: shiftedEndDateTime || DEFAULT_END_DATE_TIME
    }
    const pagination = {
      pageNumber: customPageNumber || currentPageNumber,
      pageSize
    }

    try {
      setIsLoading(true)
      const { stores } = getUserData()

      const storeId = !isAdminUser ? stores[0]?.id : null
      const response = await getRedemptions(
        dateRange,
        pagination,
        storeId,
        REDEMPTION_STATUS.REDEEMED
      )
      const {
        redemptions,
        pagination: { total_count }
      } = response

      data = formatTransactionHistoryTableData(redemptions)

      setTotalCount(total_count)
      setTransactionData(data)
    } catch (err) {
      setTransactionData([])
      setErrMsg('transactionsHistoryError', intl)
      console.log(err)
    } finally {
      if (
        dateRange.startDateTime === DEFAULT_START_DATE_TIME &&
        dateRange.endDateTime === DEFAULT_END_DATE_TIME &&
        pagination.pageNumber === DEFAULT_PAGE_NUMBER
      ) {
        lastUpdatedTime.current = new Date()
      }
      setIsLoading(false)
    }
  }

  async function getTransactionForDownloads(customTotalCount = null) {
    const [startDate, endDate] = dates
    const shiftedStartDateTime =
      startDate && startDate.set(START_TIME).format(DATE_TIME_FORMAT)
    const shiftedEndDateTime =
      endDate && endDate.set(END_TIME).format(DATE_TIME_FORMAT)
    const dateRange = {
      startDateTime: shiftedStartDateTime || DEFAULT_START_DATE_TIME,
      endDateTime: shiftedEndDateTime || DEFAULT_END_DATE_TIME
    }
    const pagination = {
      pageNumber: 1,
      pageSize: customTotalCount || totalCount
    }

    try {
      setIsDownloadDataLoading(true)
      const { stores } = getUserData()

      const storeId = !isAdminUser ? stores[0]?.id : null
      const response = await getExcelRedemptions(
        dateRange,
        pagination,
        storeId,
        REDEMPTION_STATUS.REDEEMED
      )

      return response
    } catch (err) {
      setErrMsg('transactionsHistoryError', intl)
      console.log(err)
    } finally {
      if (
        dateRange.startDateTime === DEFAULT_START_DATE_TIME &&
        dateRange.endDateTime === DEFAULT_END_DATE_TIME &&
        pagination.pageNumber === DEFAULT_PAGE_NUMBER
      ) {
        lastUpdatedTime.current = new Date()
      }
      setIsDownloadDataLoading(false)
    }
  }

  function handleDateChange(selectedDates) {
    setCurrentPageNumber(DEFAULT_PAGE_NUMBER)
    const newDates = selectedDates || []

    setDates(newDates)
  }

  const handleRefresh = () => {
    setCurrentPageNumber(DEFAULT_PAGE_NUMBER)
    setSearchString('')
    if (dates?.length) {
      setDates([])
    } else {
      getTransactions(DEFAULT_PAGE_NUMBER)
    }
  }

  const handleDownload = async () => {
    const [startDate, endDate] = dates

    if (!startDate && !endDate) {
      setShowPopup(true)

      return true
    }
    if (!transactionData.length) {
      setShowPopup(true)

      return true
    }

    const downloadData = await getTransactionForDownloads()
    const url = window.URL.createObjectURL(new Blob([downloadData]))

    //  Create a link element and trigger the download
    const link = document.createElement('a')

    link.href = url
    link.setAttribute('download', 'data.xlsx')
    document.body.appendChild(link)
    link.click()
    // Clean up
    window.URL.revokeObjectURL(url)
  }

  function handleSearch(searchInput) {
    if (!searchString || !searchInput) {
      !searchInput && setSearchString('')
      setTransactionData(data)

      return
    }
    const filteredData = data.filter(({ transactionId, storeDetails }: any) => {
      const lowerCaseSearchInput = searchString.toLowerCase()

      return (
        transactionId.toLowerCase().includes(lowerCaseSearchInput) ||
        (storeDetails?.storeName &&
          storeDetails.storeName.toLowerCase().includes(lowerCaseSearchInput))
      )
    })

    setTransactionData(filteredData)
  }

  function handlePageAndSizeChange(page, itemPerPage) {
    setCurrentPageNumber(page)
    pageSize === itemPerPage ? getTransactions(page) : setPageSize(itemPerPage)
  }

  function renderRefreshButton() {
    return (
      <Row justify="end" style={{ margin: 0 }} align="middle">
        <Text level="body-2" style={{ color: COLORS.GRAY_TEXT }}>
          <i>
            {' '}
            {intl.formatMessage({ id: 'lastUpdateWas' })}{' '}
            <TimeAgo
              date={lastUpdatedTime.current}
              formatter={(value, unit) =>
                `${value} ${value <= 1 ? unit : `${unit}s`} ago`
              }
            />{' '}
            :{' '}
          </i>
        </Text>
        <Button
          type="link"
          style={{ margin: 0, fontWeight: 'bold' }}
          onClick={handleRefresh}>
          <img
            alt="refresh"
            style={{ width: 16, height: 16, marginRight: 5 }}
            src={ICONS.refresh}
          />
          {intl.formatMessage({ id: 'refreshNow' })}
        </Button>
      </Row>
    )
  }
  const disabledDate = current => {
    // Disable dates that are after today
    return current && current > moment().endOf('day')
  }

  function renderFilters() {
    return (
      <Row
        style={{
          marginTop: 18,
          display: activeTabIndex === DEFAULT_ACTIVE_KEY ? 'flex' : 'none',
          justifyContent: 'space-between'
        }}>
        <Row style={{ margin: 0, width: '100%' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center'
            }}>
            <Search
              allowClear
              value={searchString}
              placeholder={intl.formatMessage({
                id: 'searchByTransactionIdStoreName'
              })}
              onChange={e => setSearchString(e?.target?.value)}
              onSearch={handleSearch}
              style={{ width: 400, marginTop: 15, marginLeft: 20 }}
            />
            <div
              style={{
                display: 'flex',
                marginRight: 30,
                flexDirection: 'column'
              }}>
              <Text level="caption">
                <IntlMessages id="orderHistory.selectDate" />
              </Text>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <RangePicker
                  format={DATE_FORMAT}
                  separator="|"
                  value={dates}
                  style={{ height: 37 }}
                  onChange={handleDateChange}
                  disabledDate={disabledDate}
                />
                <Button
                  type="primary"
                  style={{
                    marginLeft: 30,
                    fontWeight: 'bold',
                    marginBottom: 0
                  }}
                  loading={isDownloadDataLoading}
                  disabled={IsLoading}
                  onClick={handleDownload}>
                  <img
                    alt="download"
                    style={{ width: 21, height: 36, marginRight: 5 }}
                    src={ICONS.download}
                  />
                  {intl.formatMessage({ id: 'downloadReport' })}
                </Button>
              </div>
            </div>
          </div>
        </Row>
      </Row>
    )
  }

  function renderSettlementsFilters() {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          marginBottom: 20,
          justifyContent: 'flex-end'
        }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: 10, marginTop: 15 }}>
            <IntlMessages id="filters" />
          </div>
          <Dropdown
            type="withTitle"
            data={stores}
            label={
              <span style={{ marginTop: 50 }}>
                <IntlMessages id="selectStore" />
              </span>
            }
            size="small"
            isDisabled={!stores?.length}
            onButtonClick={item => setSelectedStore(item)}
            onChangeItem={item => {
              setSelectedStore(item)
            }}
            selectedItem={selectedStore}
          />
        </div>
        <div style={{ marginLeft: 10 }}>
          <div style={{ fontSize: 12 }}>
            <IntlMessages id="orderHistory.selectDate" />
          </div>
          <RangePicker
            format={YEAR_TO_DATE_FORMAT}
            separator="|"
            style={{ height: 36, borderRadius: 5, borderColor: '#e6e6e6' }}
            onChange={onDateChange}
          />
        </div>
      </div>
    )
  }
  function onDateChange(selectedDates) {
    const newDates = selectedDates || []

    setStoreEndDate(newDates?.[0])
    setStoreStartDate(newDates?.[1])
  }

  // Call once on mount
  useEffect(() => {
    data = []
    brandDetailedReportUrl()
    settlementReportUrl()
    brandOutletRedemptionReport()
    merchantSettlementMemoReportUrl()
  }, [])

  useEffect(() => {
    data = []
    getTransactions()
  }, [dates, pageSize])

  useEffect(() => {
    settlementReportUrl()
  }, [storeStartDate, storeEndDate, selectedStore])

  const brandDetailedReportUrl = async () => {
    const payload = {
      question_id:
        process.env.REACT_APP_PROMOTION_VENUE_LEVEL_BUSINESS_REPORTS_QUESTION_ID
    }
    const res = await getMetabaseUrl(payload)

    setBrandReport(res)
  }

  const settlementReportUrl = async () => {
    const payload = {
      question_id:
        process.env.REACT_APP_PROMOTION_SETTLEMENT_REPORTS_QUESTION_ID,
      params: {
        org_id: getWcoreOrganizationId(),
        store_name: selectedStore?.name || 'x',
        start_dt:
          moment(storeStartDate).format(YEAR_TO_DATE_FORMAT) ||
          DEFAULT_START_DATE, // '2020-10-01',
        end_dt:
          moment(storeEndDate).format(YEAR_TO_DATE_FORMAT) ||
          DEFAULT_END_DATE_TIME // '2030-10-30'
      }
    }
    const res = await getMetabaseUrl(payload)

    setSettlementReport(res)
  }
  const brandOutletRedemptionReport = async () => {
    const payload = {
      question_id:
        process.env
          .REACT_APP_PROMOTION_OUTLET_LEVEL_BUSINESS_REPORTS_QUESTION_ID
    }
    const res = await getMetabaseUrl(payload)

    setBrandOutletRedemptionUrl(res)
  }
  const merchantSettlementMemoReportUrl = async () => {
    const payload = {
      question_id:
        process.env
          .REACT_APP_PROMOTION_MONTHLY_LEVEL_SETTLEMENT_REPORTS_QUESTION_ID
    }
    const res = await getMetabaseUrl(payload)

    setMerchantSettlementMemoReport(res)
  }

  return (
    <div style={{ padding: '0 20px', marginTop: '30px', position: 'relative' }}>
      <Tabs
        defaultActiveKey={DEFAULT_ACTIVE_KEY}
        onChange={key => setActiveTabIndex(key)}>
        <TabPane
          tab={
            <>
              {intl.formatMessage({ id: 'transactionHistory' })}
              <Tooltip title={DEALS_TABS_HELPER_TEXT.transactionHistory}>
                <QuestionCircleOutlined
                  style={{ color: 'inherit', marginLeft: 20 }}
                />
              </Tooltip>
            </>
          }
          key="1"
          style={{ marginBottom: 20 }}>
          {renderFilters()}
          {renderRefreshButton()}
          <Table
            loading={IsLoading}
            columns={transactionHistoryTableColumns}
            dataSource={transactionData}
            pagination={{
              current: currentPageNumber,
              pageSize,
              total: totalCount,
              onChange: handlePageAndSizeChange
            }}
          />
        </TabPane>
        {showPopup && (
          <Modal
            visible={showPopup}
            title="Information"
            style={{ height: '30%' }}
            onClose={() => setShowPopup(false)}>
            <div style={{ margin: '10px 0' }}>
              <Text level="body-2">
                <IntlMessages
                  id={
                    !transactionData.length
                      ? 'downloadReport.emptyDataWarning'
                      : 'downloadReport.emptyDateRangeWarning'
                  }
                />
              </Text>
            </div>
            <div style={{ textAlign: 'end' }}>
              <Button
                type="primary"
                onClick={() => setShowPopup(false)}
                style={{ margin: 0 }}>
                <IntlMessages id="okay" />
              </Button>
            </div>
          </Modal>
        )}
        {isAdminUser && (
          <TabPane
            tab={
              <>
                {intl.formatMessage({ id: 'settlementReports' })}
                <Tooltip title={DEALS_TABS_HELPER_TEXT.settlement}>
                  <QuestionCircleOutlined
                    style={{ color: 'inherit', marginLeft: 20 }}
                  />
                </Tooltip>
              </>
            }
            key="2">
            <div>{renderSettlementsFilters()}</div>
            <div>
              <MetabaseReports
                dashboardURL={settlementReport?.body}
                isTitled={false}
                storeName={selectedStore?.name}
                startDate={storeStartDate}
                endDate={storeEndDate}
              />
            </div>
          </TabPane>
        )}
        {isAdminUser && (
          <TabPane
            tab={
              <>
                {intl.formatMessage({ id: 'merchantSettlementMemo' })}
                <Tooltip title={DEALS_TABS_HELPER_TEXT.invoices}>
                  <QuestionCircleOutlined
                    style={{ color: 'inherit', marginLeft: 20 }}
                  />
                </Tooltip>
              </>
            }
            key="3">
            <MetabaseReports
              dashboardURL={merchantSettlementMemoReport?.body}
              isTitled={false}
            />
          </TabPane>
        )}
        {isAdminUser && (
          <TabPane
            tab={
              <>
                {intl.formatMessage({ id: 'brandDetailedReport' })}
                <Tooltip title={DEALS_TABS_HELPER_TEXT.brandDetailsReport}>
                  <QuestionCircleOutlined
                    style={{ color: 'inherit', marginLeft: 20 }}
                  />
                </Tooltip>
              </>
            }
            key="4">
            <MetabaseReports
              dashboardURL={brandReport?.body}
              isTitled={false}
            />
          </TabPane>
        )}
        <TabPane
          tab={
            <>
              {intl.formatMessage({ id: 'brandOutletRedemptions' })}
              <Tooltip title={DEALS_TABS_HELPER_TEXT.brandOutletRedemptions}>
                <QuestionCircleOutlined
                  style={{ color: 'inherit', marginLeft: 20 }}
                />
              </Tooltip>
            </>
          }
          key="5">
          <MetabaseReports
            dashboardURL={brandOutletRedemptionUrl?.body}
            isTitled={false}
          />
        </TabPane>
      </Tabs>
    </div>
  )
}
export default PromotionsHistory
