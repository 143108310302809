import jwt from 'jsonwebtoken'

export const getFileSystemId = () => {
  return localStorage.getItem('fileSystemId')
}

export const getAuthToken = () => {
  return localStorage.getItem('jwt')
}

export const getWcoreOrganizationId = () => {
  const jwtToken = getAuthToken()

  return jwt.decode(jwtToken).org_id
}

export const getChannelsData = () => {
  const channels = localStorage.getItem('channels')

  return channels ? JSON.parse(channels) : []
}

export const getStoreFormats = () => {
  const storeFormats = localStorage.getItem('storeFormats')

  return storeFormats ? JSON.parse(storeFormats) : []
}

export const getUserData = () => {
  return JSON.parse(localStorage.getItem('userData'))
}

export const getDiscountTypes = () => {
  return JSON.parse(localStorage.getItem('discountTypes'))
}

export const getIsUnsavedStoreDetails = () => {
  return JSON.parse(localStorage.getItem('isUnsavedStoreDetails'))
}

export const getPosData = () => {
  return JSON.parse(localStorage.getItem('posData'))
}
export const getPosEnableStores = () => {
  return JSON.parse(localStorage.getItem('posEnableStores'))
}
export const getSubscriptionNotification = () => {
  return localStorage.getItem('subscriptionNotification')
}
