import 'firebase/compat/messaging'

import { message } from 'antd'
import firebase from 'firebase/compat/app'
import { getUserDetails } from 'Utils'

import { ENVIRONMENT, FCM_VAPIDKEY_DEV_MASTER, FCM_VAPIDKEY_PROD, FIREBASE_CONFIG_DEV_MASTER, FIREBASE_CONFIG_PROD, LISTENER_TYPE, NOTIFICATION_TYPE } from './Utils/constants'

const env = process.env.REACT_APP_SENTRY_ENV

declare type FrameSize = {
  top: number
  right: number
  bottom: number
  left: number
}
class FbNotificationManager {
  private static instance: any

  fb: any

  messaging: any

  orderCountUpdateListener: any

  refreshOrderListener: any

  menuUploadListener: any

  isMenuUploading: boolean

  private constructor() {
    this.fb = firebase.initializeApp(env === ENVIRONMENT.PRODUCTION ? FIREBASE_CONFIG_PROD : FIREBASE_CONFIG_DEV_MASTER)
    console.log('environment', env)
    this.messaging = firebase.messaging.isSupported() ? this.fb.messaging() : null
    const isMenuUploading = JSON.parse(localStorage.getItem('isMenuUploading'))

    if (isMenuUploading === null) {
      this.isMenuUploading = false
      localStorage.setItem('isMenuUploading', JSON.stringify(false))
    } else {
      this.isMenuUploading = isMenuUploading
      localStorage.setItem('isMenuUploading', isMenuUploading)
    }
  }

  public static getInstance(): FbNotificationManager {
    if (!FbNotificationManager.instance) {
      FbNotificationManager.instance = new FbNotificationManager()
    }

    return FbNotificationManager.instance
  }

  public getFirebaseNotificationToken = () => {
    return new Promise((resolve, reject) => {
      const FCM_VAPIDKEY = env === ENVIRONMENT.PRODUCTION ? FCM_VAPIDKEY_PROD : FCM_VAPIDKEY_DEV_MASTER

      if (!this.messaging) {
        const err = new Error('Firebase notification is not supported with current browser setting')

        throw err
      }
      this.messaging
        .getToken({ vapidKey: FCM_VAPIDKEY })
        .then(firebaseToken => {
          resolve(firebaseToken)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  public removeFirebaseToken = () => {
    console.log('Firebase token deleted')
    this.messaging.deleteToken()
  }

  public initNotificationListener = () => {
    if (!this.messaging) return
    console.log('Notification msg handler is registered')
    this.messaging.onMessage(noteData => {
      console.log('received notifications')
      console.log(noteData)
      if (!getUserDetails()) {
        return
      }
      const notification = noteData.data['gcm.notification.data'] ? JSON.parse(noteData.data['gcm.notification.data']) : noteData.data

      if (!notification.type) {
        notification.type = NOTIFICATION_TYPE.NEW_ORDER
      }
      switch (notification.type) {
          case NOTIFICATION_TYPE.MENU_UPLOAD_UPDATE:
            this.updateProp('isMenuUploading', false)
            message.success(notification.title)
            this.menuUploadListener && this.menuUploadListener(notification)
            break
          case NOTIFICATION_TYPE.NEW_ORDER:
            this.orderCountUpdateListener && this.orderCountUpdateListener(notification)
            this.refreshOrderListener && this.refreshOrderListener(notification)
            break
          case NOTIFICATION_TYPE.ORDER_UPDATE:
            this.orderCountUpdateListener && this.orderCountUpdateListener(notification)
            this.refreshOrderListener && this.refreshOrderListener(notification)
            break
      }
    })
  }

  public addListener = (listenerType, listenerFunc) => {
    switch (listenerType) {
        case LISTENER_TYPE.NEW_ORDER:
          this.orderCountUpdateListener = listenerFunc
          break
        case LISTENER_TYPE.ORDER_REFRESH:
          this.refreshOrderListener = listenerFunc
          break
        case LISTENER_TYPE.MENU_UPLOAD_UPDATE:
          this.menuUploadListener = listenerFunc
          break
    }
  }

  public removeListener = listenerType => {
    switch (listenerType) {
        case LISTENER_TYPE.NEW_ORDER:
          this.orderCountUpdateListener = null
          break
        case LISTENER_TYPE.ORDER_REFRESH:
          this.refreshOrderListener = null
          break
        case LISTENER_TYPE.MENU_UPLOAD_UPDATE:
          this.menuUploadListener = null
          break
    }
  }

  public updateProp(key: string, value: any) {
    this[key] = value
    if (key === 'isMenuUploading') {
      localStorage.setItem(key, JSON.stringify(value))
    }
  }

  public getValue(key: string) {
    if (key === 'isMenuUploading') {
      return JSON.parse(localStorage.getItem('isMenuUploading'))
    }

    return this[key]
  }
}

export default FbNotificationManager